import { useEffect, useState } from "react";

const Game = () => {
  const deck = [
    "AC",
    "AD",
    "AH",
    "AS",
    "2C",
    "2D",
    "2H",
    "2S",
    "3C",
    "3D",
    "3H",
    "3S",
    "4C",
    "4D",
    "4H",
    "4S",
    "5C",
    "5D",
    "5H",
    "5S",
    "6C",
    "6D",
    "6H",
    "6S",
    "7C",
    "7D",
    "7H",
    "7S",
    "8C",
    "8D",
    "8H",
    "8S",
    "9C",
    "9D",
    "9H",
    "9S",
    "10C",
    "10D",
    "10H",
    "10S",
    "JC",
    "JD",
    "JH",
    "JS",
    "QC",
    "QD",
    "QH",
    "QS",
    "KC",
    "KD",
    "KH",
    "KS",
  ];
  const [currentMove, setCurrentMove] = useState(1);
  const [drinkTally, setDrinkTally] = useState(0);
  const [selectedCards, setSelectedCards] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);

  useEffect(() => {
    createDeck();
  }, []);

  const createDeck = () => {
    const cardNumbers = Array(52)
      .fill()
      .map((_, index) => index + 1);
    cardNumbers.sort(() => Math.random() - 0.5);
    console.log(cardNumbers);
    setSelectedCards([deck[cardNumbers[0]], deck[cardNumbers[1]], deck[cardNumbers[2]], deck[cardNumbers[3]]]);
  };

  const resetGame = () => {
    setCurrentMove(1);
    createDeck();
    setGameOver(false);
    setGameWon(false);
  };

  const convertCharCard = (card) => {
    if (card === "A") return 1;
    else if (card === "J") return 11;
    else if (card === "Q") return 12;
    else if (card === "K") return 13;
    else return parseInt(card);
  };

  const roundOne = (color) => {
    // Rautt = H, D
    // Svart = S, C
    console.log("Round 1:", selectedCards);
    const firstCard = selectedCards[0].slice(-1);
    if ((color === "R" && firstCard === "H") || (color === "R" && firstCard === "D")) {
      setCurrentMove(2);
    } else if ((color === "B" && firstCard === "S") || (color === "B" && firstCard === "C")) {
      setCurrentMove(2);
    } else {
      setDrinkTally((drinkTally) => drinkTally + 1);
      setCurrentMove(2);
      setGameOver(true);
    }
  };

  const roundTwo = (option) => {
    const firstCard = convertCharCard(selectedCards[0].substring(0, selectedCards[0].length - 1));
    const secondCard = convertCharCard(selectedCards[1].substring(0, selectedCards[1].length - 1));
    console.log("Round 2:", option, firstCard, secondCard);
    if (option === "H" && secondCard >= firstCard) {
      setCurrentMove(3);
    } else if (option === "L" && secondCard <= firstCard) {
      setCurrentMove(3);
    } else {
      setDrinkTally((drinkTally) => drinkTally + 2);
      setCurrentMove(3);
      setGameOver(true);
    }
  };

  const roundThree = (option) => {
    // Milli = I
    // Utan = O
    const firstCard = convertCharCard(selectedCards[0].substring(0, selectedCards[0].length - 1));
    const secondCard = convertCharCard(selectedCards[1].substring(0, selectedCards[1].length - 1));
    const thirdCard = convertCharCard(selectedCards[2].substring(0, selectedCards[2].length - 1));
    console.log("Round 3:", option, firstCard, secondCard, thirdCard);
    if (firstCard === secondCard && option === "O") {
      setCurrentMove(4);
    } else if (option === "O" && firstCard > secondCard && (thirdCard >= firstCard || thirdCard <= secondCard)) {
      setCurrentMove(4);
    } else if (option === "O" && firstCard < secondCard && (thirdCard <= firstCard || thirdCard >= secondCard)) {
      setCurrentMove(4);
    } else if (option === "I" && firstCard > secondCard && thirdCard <= firstCard && thirdCard >= secondCard) {
      setCurrentMove(4);
    } else if (option === "I" && firstCard < secondCard && thirdCard >= firstCard && thirdCard <= secondCard) {
      setCurrentMove(4);
    } else {
      setDrinkTally((drinkTally) => drinkTally + 3);
      setCurrentMove(4);
      setGameOver(true);
    }
  };

  const roundFour = (sort) => {
    console.log("Round 4:", selectedCards);
    const fourthCard = selectedCards[3].slice(-1);
    if (sort === fourthCard) {
      setGameWon(true);
    } else {
      setGameOver(true);
      setDrinkTally((drinkTally) => drinkTally + 4);
    }
  };

  return (
    <main
      className="h-screen w-screen flex items-center flex-col font-mono overflow-hidden"
      style={{
        background: "rgba(0,0,0,1)",
        background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 70%, rgba(168,0,0,1) 100%)",
      }}
    >
      <header className="py-10">
        <h1 className="text-white text-5xl">Helvíti</h1>
        <span className="text-white">Þú hefur tekið {drinkTally} sopa</span>
      </header>
      <section className="flex flex-col gap-5 z-20 mb-10">
        <div className="flex gap-5 lg:gap-10">
          {currentMove >= 2 ? (
            <img src={process.env.PUBLIC_URL + `/cards/PNG/${selectedCards[0]}.png`} />
          ) : (
            <img src={process.env.PUBLIC_URL + "/cards/CardBack.png"} className="opacity-50" />
          )}
          {currentMove >= 3 ? (
            <img src={process.env.PUBLIC_URL + `/cards/PNG/${selectedCards[1]}.png`} />
          ) : (
            <img src={process.env.PUBLIC_URL + "/cards/CardBack.png"} className="opacity-50" />
          )}
          {currentMove >= 4 ? (
            <img src={process.env.PUBLIC_URL + `/cards/PNG/${selectedCards[2]}.png`} />
          ) : (
            <img src={process.env.PUBLIC_URL + "/cards/CardBack.png"} className="opacity-50" />
          )}
          {(currentMove == 4 && gameOver) || (currentMove == 4 && gameWon) ? (
            <img src={process.env.PUBLIC_URL + `/cards/PNG/${selectedCards[3]}.png`} />
          ) : (
            <img src={process.env.PUBLIC_URL + "/cards/CardBack.png"} className="opacity-50" />
          )}
        </div>
        {gameOver && (
          <div className="flex items-center justify-center flex-col z-10">
            <h1 className="text-white text-2xl font-bold">GAME OVER!</h1>
            <button className="text-white p-2 bg-red-700 rounded-md hover:cursor-pointer" onClick={() => resetGame()}>
              RESTART
            </button>
          </div>
        )}
        {gameWon && (
          <div className="flex items-center justify-center flex-col z-10">
            <h1 className="text-white text-2xl font-bold">ÞÚ KOMST ÚT ÚR HELVÍTI! GG</h1>
            <button className="text-white p-2 bg-red-700 rounded-md hover:cursor-pointer" onClick={() => resetGame()}>
              RESTART
            </button>
          </div>
        )}
      </section>

      <section className="flex items-center justify-center w-full z-10">
        {currentMove === 1 && (
          <div className="flex flex-col gap-5">
            <h3 className="text-white text-3xl">Svart eða rautt?</h3>
            <div className="flex items-center justify-center gap-5">
              <img
                src={process.env.PUBLIC_URL + "/cards/PNG/AH.png"}
                className="hover:cursor-pointer"
                onClick={() => roundOne("R")}
              />
              <img
                src={process.env.PUBLIC_URL + "/cards/PNG/AS.png"}
                className="hover:cursor-pointer"
                onClick={() => roundOne("B")}
              />
            </div>
          </div>
        )}
        {currentMove === 2 && !gameOver && (
          <div>
            <h3 className="text-white text-3xl font-mono">Hærra eða lægra?</h3>
            <div className="flex justify-center items-center gap-10">
              <button className="text-white font-mono p-3 bg-orange-400 rounded-md" onClick={() => roundTwo("H")}>
                Hærra
              </button>
              <button className="text-white font-mono p-3 bg-orange-400 rounded-md" onClick={() => roundTwo("L")}>
                Lægra
              </button>
            </div>
          </div>
        )}
        {currentMove === 3 && !gameOver && (
          <div className="flex flex-col gap-5">
            <h3 className="text-white text-3xl font-mono">Á milli eða fyrir utan?</h3>
            <div className="flex justify-center items-center gap-10">
              <button className="text-white font-mono p-3 bg-orange-400 rounded-md" onClick={() => roundThree("I")}>
                Milli
              </button>
              <button className="text-white font-mono p-3 bg-orange-400 rounded-md" onClick={() => roundThree("O")}>
                Utan
              </button>
            </div>
          </div>
        )}
        {currentMove === 4 && !gameOver && !gameWon && (
          <div className="flex items-center flex-col gap-5">
            <h3 className="text-white text-3xl font-mono">Veldu sort</h3>
            <div className="flex gap-5">
              <img src={process.env.PUBLIC_URL + "cards/PNG/AH.png"} onClick={() => roundFour("H")} />
              <img src={process.env.PUBLIC_URL + "cards/PNG/AS.png"} onClick={() => roundFour("S")} />
              <img src={process.env.PUBLIC_URL + "cards/PNG/AD.png"} onClick={() => roundFour("D")} />
              <img src={process.env.PUBLIC_URL + "cards/PNG/AC.png"} onClick={() => roundFour("C")} />
            </div>
          </div>
        )}
      </section>
      <footer
        className="absolute w-full bottom-0 h-1/2 bg-[center_bottom_-2rem] lg:bg-[center_top_1rem]"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + "fire2.gif"})`,
          backgroundRepeat: "repeat-x",
        }}
      ></footer>
    </main>
  );
};

export default Game;
